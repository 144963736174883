.search-result-link-follow {
    width: 100%;
    max-width: 450px;
    margin-bottom: 25px;
    text-align: -webkit-center;
}

.prospect-background-follow {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
}

.prospect-detail-follow {
    margin-left: 20px;
    width: 100%;
}

.show-more-following-button {
    background-color: #ffffff !important;
}

.show-more-following-button:hover {
    background-color: #6c757d !important;
}

.clear-search {
    margin-left: 20px;
    font-size: 13px;
    color: gray;
    text-decoration: underline;
    cursor: pointer;
}

.clear-search:hover {
    color: lightgray;
}

.relationship-tag {
    font-size: 12px;
    height: 20px;
    padding: 0px 10px;
    color: #ffffff;
    border-radius: .25rem;
    white-space: nowrap;
}