.header {
    min-height: 82px;
    background: linear-gradient(to right, #00acc4 0%, #5d7b9a 100%);
    flex-wrap: nowrap !important;
    align-items: stretch !important;
    padding: 0px !important;
}

.header-title {
    margin-left: 30px;
}

.header-title-text {
    font-weight: 300;
    color: white;
    margin-bottom: 0px;
    margin-top: 10px;
    font-size: 2.75rem;
}

.proconnect-logo {
    margin-bottom: 15px;
    width: 45px;
}

.header-searchbar {
    width: 100% !important;
    height: 45px !important;
}

.header-searchbar-radius {
    border-bottom-right-radius: 0rem !important;
    border-bottom-left-radius: 0rem !important;
}

.header-searchbar-button {
    width: 90px;
    background-color: #f6871f !important;
    border-color: #f6871f !important;
    height: 45px;
}

.searchbar-section {
    width: 70% !important;
    max-width: 680px;
    min-width: 70%;
    position: relative;
    display: inline-block;
    margin-right: 10px;
}

.searchbar-result {
    position: absolute;
    border: 1px solid #ced4da;
    border-top: none;
    z-index: 99;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.dropdown-toggle::after {
    display: none !important;
}

.header-dropdown .dropdown {
    height: 100%;
}

.header-dropdown .dropdown-toggle {
    height: 100%;
    background-color: #3c3d3e !important;
    border-color: #3c3d3e !important;
    width: 60px;
    border-radius: 0px;
}

@media (max-width: 500px) {
    .header-dropdown .dropdown-toggle {
        width: auto;
    }

    .header-searchbar-button {
        width: auto;
    }
}

.searchbar-input-button {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* for when header title too long in mobile view */
@media (max-width: 350px) {
    .header-title-text {
        font-size: 1.75rem;
    }
}

#button-addon1:focus {
    outline: none !important;
    box-shadow: none !important;
}