.search-result {
    margin-top: 40px;
    margin-bottom: 50px;
}

.no-result {
    color: #000000;
    font-family: Calibri;
    font-size: 26px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    padding: 40px 20px 50px 20px;
    font-family: Arial, Helvetica, sans-serif;
}

.no-history {
    color: #000000;
    font-family: Calibri;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    padding: 40px 20px 50px 20px;
    font-family: Arial, Helvetica, sans-serif;
}

.search-result-card {
    max-width: 400px;
}

.card-deck {
    justify-content: center;
    text-align: -webkit-center;
}

.search-result-link {
    max-width: 300px;
    width: 100%;
    margin-bottom: 40px;
}

.search-result-paging {
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 50px;
    display: flex;
    justify-content: space-between;
}

.filter-section {
    min-width: 250px;
    background-color: #00ACC4;
    padding-top: 20px;
    color: white;
}

.filter-title {
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 10px;
    margin-left: 10px;
}


@media (min-width: 990px) {
    .filter-title {
        margin-top: 10px;
    }

}

.search-result-section {
    padding-top: 30px;
    padding-bottom: 30px;
}

.bold-500 {
    font-weight: 500;
}

.row-results {
    box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, 0.3);
    background-color: #ffffff;
}

@media (min-width: 990px) and (max-width: 1500px) {
    .row-results {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}

.search-title {
    margin-bottom: 20px;
}

@media (min-width: 1500px) {
    .search-title {
        margin-left: -15px;
    }
}

.search-profile-picture {
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    border-color: #00acc4;
}

.sortby-dropdown .dropdown-toggle {
    background-color: #ffffff;
    color: #004069;
    border: 1px solid #5d7b9a;
    border-radius: 0px;
}

.sort-result-section {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 30px 20px;
}

.sortby-section {
    display: flex;
}

.filter-options {
    padding: 10px 10px 10px 20px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.filter-selected, .filter-options:hover {
    background-color: #016978;
    border-radius: 5px;
    font-weight: bold;
}

.filter-checkbox {
    padding-left: 10px
}

.filter-checkbox input[type=checkbox] {
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
}

.filter-checkbox .form-check-label {
    margin-left: 5px;
}

.filter-see-more {
    cursor: pointer;
    margin-left: 10px;
    margin-top: 10px;
    font-weight: 500;
}

/* mobile styles for Search Result page (needs to be below the class w/o @media be active) */
@media (max-width: 520px) {
    .sort-result-section {
        display: block;
    }

    .sortby-section {
        margin-top: 15px;
    }

    .search-result-link {
        max-width: 100%;
    }
}

.help-text {
    padding: 10px 10px 10px 10px;
    margin-bottom: 20px;
    font-size: 14px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.result-cards {
    font-size: xx-large;
    padding-left: 30px;
    padding-bottom: 10px;
    color: #1f7edc;
}