@media (min-width: 1200px) {
    .container-fluid {
        max-width: 1500px;
    }
}

.overview-style {
    margin-top: 40px;
    margin-bottom: 50px;
}

.card {
    border: none !important;
    box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, 0.2);
}

@media (min-width: 992px) {
    .row {
        margin-bottom: 20px;
    }
}

@media (max-width: 991px) {
    .col, [class^='col-lg-'] {
        margin-bottom: 20px;
    }
}

.card-title {
    text-align: left;
    color: #004068;
    padding: 30px 30px 10px 30px;
    font-family: Arial, Helvetica, sans-serif;
}

.card-title-active {
    border-bottom: 3px solid #F68720;
    padding-bottom: 5px;
    font-weight: bold;
}

.card-body {
    padding: unset !important;
}

.prospect-name {
    font-weight: 500;
    color: #004068;
    font-family: Arial, Helvetica, sans-serif;
    font-style: italic;
    margin-bottom: 5px;
}

.company-name {
    color: #5d7b9a;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
}

.card-title-company-name {
    color: #5d7b9a;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    text-align: left;
}

.current-title {
    color: #454444;
    font-style: italic;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
}

.prospect-fields {
    color: #004068;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 8px;
}

.header-fields {
    font-weight: bold;
}

.profile-picture {
    border-radius: 50%;
    margin-bottom: 10px;
    width: 100px;
    height: 100px;
    border-style: solid;
    border-width: 2px;
    border-color: #00acc4;
}

.prospect-background {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;
}

.text-primary:focus, .text-primary:hover {
    color: #0056b3!important;
    cursor: pointer;
}

.company-description {
    color: #454444;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 20px;
}

.company-description-header {
    justify-content: space-between;
    display: flex;
}


.website-title {
    color: #454444;
    font-weight: 500;
}

.linkedin-icon {
    color: #2867B2;
    cursor: pointer;
}

thead {
    background-color: #DCD9D9;
    color: #004068;
}

.not-available {
    margin-bottom: 30px;
    text-align: center;
}

.protiviti-work-field {
    margin-bottom: 10px;
    margin-top: 10px;
}

.fields-style {
    color: #004068;
    font-weight: 500;
}

.card-style {
    border-style: solid;
    border-top-width: 3px;
    border-right-width: 0.5px;
    border-bottom-width: 0.5px;
    border-left-width: 0.5px;
    border-color: #c3cedb;
}

.newsFeed {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

    .newsFeed > li:nth-of-type(odd) {
        background-color: #F2F2F2;
    }

.solution-tooltip {
    color: #ffffff;
    background-color: #004068;
    padding-left: 15px;
    padding-right: 15px;
}

.icon-protiviti-logo {
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
}

.solution-guide {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 30px 20px 40px 20px;
    width: 220px;
}

.solution-pie {
    position: relative;
}

.solution-guide-item {
    display: flex;
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 20px;
}

.solution-guide-color {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-top: 1px;
}

.sfdc-contact-owner {
    text-align: end;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.internal-connection-picture {
    border-style: solid;
    border-width: 2px;
}

.connection-icons {
    width: 1em;
    height: 1em;
    cursor: pointer;
    margin-right: 10px;
}

.internal-connections-list .list-group-item {
    border-left: 0px;
    border-right: 0px;
}

.relationship-type {
    height: 25px;
    padding: 1px 3px;
    color: white;
    text-align: center;
    width: 50px;
    position: absolute; 
    bottom: 0;
    right: 0;
    font-size: 14px;
}



.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 3px dashed;
    border-top: 3px solid \9;
    border-right: 3px solid transparent;
    border-left: 3px solid transparent;
}

.dropup, .dropdown {
    position: relative;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 3px dashed;
    border-bottom: 3px solid \9;
    content: "";
}

.card {
    height: 100%;
}

.internal-connection-modal .list-group .list-group-item {
    border: none;
}
.internal-connection-modal .list-group-item {
    padding: .25rem 1.25rem;
}

.list-group-item {
    padding: .15rem 1.75rem !important;
    font-size: 14px;
    color: #004068;
}

.card-title-modal {
    margin-bottom: 0px;
    padding: 15px 15px 5px 15px;
    text-align: left;
    color: #004068;
    font-family: Arial, Helvetica, sans-serif;
}

.custom-dialog {
    max-width: 1500px !important;
}

.internal-connection-modal #pageDropDown {
    display: none;
}

.internal-connection-modal .page-item.active .page-link {
    background-color: #5d7b9a;
    border-color: #5d7b9a;
}

.internal-connection-modal .page-link {
    color: #5d7b9a;
}

.interaction-sort {
    background-color: white;
    border-radius: 4px;
    margin-top: 0px;
    position: absolute;
    z-index: 2;
    padding: 10px;
    box-shadow: 0 0.125rem 0.625rem rgba(90, 97, 105, 0.2);
    margin-top: 10px;
}

.interaction-sort .filter-checkbox .form-check-label {
    font-weight: 500;
}

.back-button {
    margin-bottom: 10px;
    cursor: pointer;
}

.back-arrow {
    margin-bottom: 3px; 
    transform: rotate(180deg);
    color: #F6871F;
}

.back-text {
    color: #5d7b9a;
    font-weight: 500;
}

.notavailable-icon {
    color: #00ACC4;
    vertical-align: sub;
    cursor: pointer;
}

.solution-pie .dropdown-menu {
    text-align-last: start;
}

.solution-subtitle {
    padding: 0px 30px 20px 30px;
    font-size: 13px;
    color: gray;
    font-style: italic;
    font-weight: 400;
}

.solution-subtitle-modal {
    padding: 0px 30px 0px 15px;
    font-size: 13px;
    color: gray;
    font-style: italic;
    font-weight: 400;
}

.notavailable-icon {
    color: #00ACC4;
    vertical-align: sub;
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.following-button {
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.following-active {
    font-size: 12px !important;
    height: 20px !important;
    padding: 0px 10px !important;
    background-color: #ffffff !important;
    border-color: #5d7b9a !important;
    color: #5d7b9a !important;
}

.following-inactive {
    font-size: 12px !important;
    height: 20px !important;
    padding: 0px 10px !important;
    background-color: #00acc4 !important;
    border-color: #00acc4 !important;
    color: #ffffff !important;
}

.following-active:hover {
    background-color: #00acc4 !important;
    border-color: #00acc4 !important;
    color: #ffffff !important;
}


.following-inactive:hover {
    background-color: #ffffff !important;
    border-color: #5d7b9a !important;
    color: #5d7b9a !important;
}

.modified-date {
    color: #454444;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
}

.result-table .react-bootstrap-table-pagination {
    display: none;
}

.hubBtn {
    border-width: 2px !important;
    background-color: #ffffff !important;
    color: #335b86 !important;
    font-weight: bold !important;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    padding: 5px !important;
    border-color: #87a4c3 !important;
}

.employee-name {
    font-size: 14px;
    font-weight: 400;
    color: #212529;
}

.employee-info {
    font-size: 14px;
    font-weight: 400;
    color: gray;
}

.social-media-icon {
    font-size: 18px;
}

tbody {
    border-bottom: 1px solid #dee2e6;
}

.opportunity-title {
    margin-left: 15px;
    border-top: none;
    border-left: none;
    border-right: none;
    background: none;
    font-size: x-large;
    outline: none !important;
}

.modal-content {
    min-height: 600px;
}

.marketing-solution-graph {
    width: 70%;
}

.marketing-solution-legend {
    width: 30%;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 40px;
    border-left: thin solid #c8c9c7;
    display: grid;
    grid-gap: 20px;
}

.filter-clear {
    text-align: center;
    border-top: 1px solid rgba(0,0,0,.1);
    color: gray;
    padding-top: 8px;
    padding-bottom: 8px;
}

.filter-clear:hover {
    text-decoration-line: underline;
}

.space-between {
    display: flex; 
    justify-content: space-between;
}

.trigger-filter-modal {
    margin-left: auto;
    display: flex;
}

.filter-button {
    text-align: center;
    margin-right: 10px;
    margin-top: 10px;
}

.clear-filter {
    margin-top: -5px;
}

.card-style.modal-header {
    border-right-width: 0px;
    border-left-width: 0px;
}

.modal-header .close {
    margin-left: 0px !important;
}

.project-subtitle {
    padding: 5px 30px 0px 4px;
    font-size: 13px;
    color: gray;
    font-style: italic;
    font-weight: 400;
    width: 460px;
}

.child-show {
    color: gray;
    cursor: pointer;
}

.child-show:hover {
    color: lightgray;
}

.contact-name {
    font-size: 15px;
    padding-left: 2px;
    padding-bottom: 3px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.help-subtitle {
    padding: 0px 30px 20px 30px;
    font-size: 13px;
    color: gray;
    font-style: italic;
    font-weight: 400;
    margin-left: 23px;
}

.follow-button-margin {
    margin-bottom: 10px;
    margin-left: 13px;
}

.bi-subtitle {
    font-size: 13px;
    color: gray;
    font-style: italic;
    width: 90px;
    line-height: 1.3;
}

.graph-card-style {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.graph-card-border {
    border-style: solid;
    border-width: 3px;
    border-color: #00acc4;
}

.graph-row-style {
    flex-wrap: nowrap !important;
}

.graph-col-style {
    text-align: -webkit-center;
}

.search-result-link-graph {
    max-width: 300px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.profile-pic {
    background-color: #000;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% auto;
    background-size: contain;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    border-color: #00acc4;
    margin-left: auto;
    margin-right: auto;
}

.graph-profile-pic-wrapper {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    justify-content: center;
}

.graph-relationship {
    position: absolute;
    right: 0px;
}

.connections-subtitle {
    font-size: 16px;
    font-weight: 400;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.connections-legend {
    padding-right: 30px;
    width: 100px;
    color: #004068;
}

.connections-legend-line {
    background-color: #004068;
    border-radius: 50px;
}

.external-header {
    padding: 10px 10px 0px 20px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    color: #004068;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}

    .external-header:hover {
        background-color: #ebeeef;
        border-radius: 5px;
        font-weight: bold;
    }

.external-info {
    padding: 0px 10px 0px 20px;
    display: flex;
    justify-content: space-between;
    color: #004068;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-align: left;
}

.inner-info {
    padding: 0px 10px 10px 20px;
    display: flex;
    justify-content: space-between;
    color: #454444;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
}

.social-media-margin {
    margin-left: 5px;
}

.company-picture {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% auto;
    background-size: contain;
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
}